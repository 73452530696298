import React, { useEffect, useState } from "react";

import CategoryItem from "./categoryItem";
import { trendingCategoryData } from "../../data/categories_data";
import Tippy from "@tippyjs/react";
import Recently_added_dropdown from "../dropdown/recently_added_dropdown";
import { useSelector, useDispatch } from "react-redux";
import { updateTrendingCategoryItemData } from "../../redux/counterSlice";
import { useWallet } from "@demox-labs/aleo-wallet-adapter-react";

import {
  get_user_private_tokens,
  loadTokensData,
  get_public_tokens_owned_by,
  get_user_collections,
  loadPrivacyPridesData,
  get_user_privacy_prides
} from 'lib/client/aleo';
import { useAlert } from "react-alert";

const dafault_tranding_category_filter_data = [
  {
    id: 0,
    svg: "public",
    text: "public",
  },
  {
    id: 1,
    svg: "private",
    text: "private",
  },
  {
    id: 2,
    text: "all",
  }
];
const public_tranding_category_filter_data = [
  {
    id: 0,
    svg: "public",
    text: "public",
  }
];
const private_tranding_category_filter_data = [
  {
    id: 1,
    svg: "private",
    text: "private",
  },
];

async function loadUserWalletContent(
  publicKey,
  requestRecords,
  alert,
  filterVal,
  setFilterVal,
  setTokens,
  loading,
  setLoading,
  setCollections
) {
  if (loading || !publicKey || !requestRecords)
    return
  setLoading(true);
  const is_externals_tokens = filterVal === 3;
  const is_private_tokens = filterVal === 1 || filterVal === 2;
  const inludes_public_tokens = filterVal === 0 || filterVal === 2;
  let user_collections = [];
  try {
    if (!is_externals_tokens) {
      let ownedTokens = [];
      let records = await requestRecords(process.env.NEXT_PUBLIC_NFT_PROGRAM_ID) || [];
      records = records.filter((record) => !record.spent);
      user_collections = await get_user_collections(publicKey, requestRecords, records);

      if (is_private_tokens) {
        const private_tokens = await get_user_private_tokens(publicKey, requestRecords, records);
        ownedTokens = ownedTokens.concat(private_tokens);
      }
      if (inludes_public_tokens) {
        ownedTokens = ownedTokens.concat(
          await get_public_tokens_owned_by(publicKey)
        );
      }
      ownedTokens = ownedTokens.filter((token) => token);
      ownedTokens = await loadTokensData(ownedTokens);
      setTokens(ownedTokens);
      setCollections(user_collections);
    } else {
      const private_tokens = await get_user_privacy_prides(publicKey, requestRecords);
      const ownedTokens = await loadPrivacyPridesData(private_tokens);
      console.log({ ownedTokens });
      setTokens(ownedTokens);
      setCollections([]);
    }
  }
  catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
    if (is_private_tokens || is_externals_tokens) {
      setFilterVal(0);
    }
  }
  setLoading(false);
}

const Trending_categories_items = ({ category, setCategory, }) => {
  const [tokens, setTokens] = useState([])
  const [collections, setCollections] = useState([]);
  const dispatch = useDispatch();
  const { trendingCategorySorText } = useSelector((state) => state.counter);
  const [filterVal, setFilterVal] = useState(0);
  const { publicKey, requestRecords } = useWallet();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const [ownedPublicLoading, setOwnedPublicLoading] = useState(false);
  const [trandingCategoryFilter, setTrandingCategoryFilter] = useState(
    dafault_tranding_category_filter_data
  );

  useEffect(() => {
    if (category === 2) {
      setTrandingCategoryFilter(public_tranding_category_filter_data);
      setFilterVal(0);
    } else if (category === 4) {
      setTrandingCategoryFilter(private_tranding_category_filter_data);
      setFilterVal(1);
    }
    else {
      setTrandingCategoryFilter(dafault_tranding_category_filter_data);
    }
  }, [category]);

  useEffect(() => {
    loadUserWalletContent(
      publicKey, requestRecords, alert, filterVal, setFilterVal, setTokens, loading, setLoading, setCollections
    );
  }, [filterVal, category, publicKey, requestRecords]);

  return (
    <>
      {/* <!-- Filter --> */}
      <div className="mb-8 flex flex-wrap items-center justify-between">
        <ul className="flex flex-wrap items-center">
          {trandingCategoryFilter.map(({ id, svg, text }) => {
            if (text === "all") {
              return (
                <li className="my-1 mr-2.5" key={id}>
                  <button
                    className={
                      filterVal === id
                        ? "dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize"
                        : "dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"
                    }
                    onClick={() => {
                      setFilterVal(id);
                    }}
                  >
                    {text}
                  </button>
                </li>
              );
            } else {
              return (
                <li className="my-1 mr-2.5" key={id}>
                  <button
                    onClick={() => {
                      setFilterVal(id);
                    }}
                  >
                    <div
                      className={
                        filterVal === id
                          ? "dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize"
                          : "dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"
                      }
                    >
                      <svg
                        style={{
                          transform: "translate(1px, 2px)",
                        }}
                        className={
                          filterVal === id
                            ? "icon mr-1 h-5 w-5 transition-colors fill-white"
                            : "icon fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white"
                        }
                      >
                        <use xlinkHref={`/icons.svg#icon-${svg}`}></use>
                      </svg>
                      <span>{text}</span>
                    </div>
                  </button>
                </li>
              );
            }
          })}
        </ul>
        {/* dropdown */}

        <div className="relative">
          <div className="flex items-center justify-center">
            <div className="my-1 mr-2.5">
              <button
                className={
                  filterVal === 3
                    ? "dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize"
                    : "dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"
                }
                onClick={() => {
                  setFilterVal(3);
                }}
              >
                External NFTs
              </button>
            </div>
          </div>
        </div>

      </div>

      {/* <!-- Grid --> */}
      {loading && (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-jacarta-500">
          </div>
        </div>
      )
      }
      {!loading && tokens.length === 0 && (
        <div className="flex justify-center items-center">
          <div className="text-center">
            <div className="text-2xl font-semibold mb-2">No {
              filterVal === 0 ? "public" : (
                filterVal === 1 ? "private" : "")
            } token found</div>
            <div className="text-sm text-jacarta-500 dark:text-jacarta-400 normal-case	">
              Begin your journey by creating a new item
            </div>
          </div>
        </div>
      )}
      {!loading && (<CategoryItem tokens={tokens} collections={collections} />)}
    </>
  );
};

export default Trending_categories_items;
